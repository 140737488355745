import {number} from 'yup';
import {Response} from '../../../_metronic/helpers';

export interface Model {
  id?: number;
  user_id?: number;
  model_name?: string;
  category_id?: number;
  catalog_id?: string;
  location?: string;
  custom_location?: string;
  created_date?: Date;
  status: ModelStatusesEnum;
  status_name?: string;
  category_name?: string;
  reason?: string;
  pub_id?: string;
  is_shared?: boolean;
  is_downloaded?: boolean;
  count_3d?: number;
  count_ar?: number;
  error_code?: number;
  ai_version?: string;
  parent_model_pub_id?: string;
  parent_model_pub_name?: string;
  rating?: number;
  api_key?: string;
  user_name?: string;
  company?: string;
  email?: string;
  is_sunglasses?: boolean;
  images?: File[];
  model_thumbnail?: string;
  source_images?: ModelSourceImage[];
  org_id?: number;

  frame_width?: number;
  frame_height?: number;
  lens_width?: number;

  // frame_thickness?: number;
  // temples_thickness?: number;
  // frame_transparency?: 'High' | 'Medium' | 'Low';
  // lens_transparency?: 'High' | 'Medium' | 'Low';
  // mirror_lenses?: boolean;

  has_custom_format?: boolean;
  format_name?: string;
  is_advanced_fields_set?: boolean;
}

export class ModelsCounter {
  done: number = 0;
  pendingOrNotStarted: number = 0;
  processing: number = 0;
  error: number = 0;
  approved: number = 0;
  rejected: number = 0;

  get total(): number {
    return (
      this.done +
      this.pendingOrNotStarted +
      this.processing +
      this.error +
      this.approved +
      this.rejected
    );
  }
}

export class TimeTracker {
  totalRunningTime: string;
  totalStoppedTime: string;
  error?: string;

  constructor(runningTime: string = '0h 0m 0s', stoppedTime: string = '0h 0m 0s', error = '') {
    this.totalRunningTime = runningTime;
    this.totalStoppedTime = stoppedTime;
    this.error = error;
  }
}

export class ServersTimeTracker {
  models: TimeTracker;
  videos: TimeTracker;

  constructor() {
    this.models = new TimeTracker();
    this.videos = new TimeTracker();
  }
}

export interface Category {
  id: number;
  name: string;
  icon: string;
  has_ar?: boolean;
  editable?: boolean;
  max_number_of_photos?: number;
  min_number_of_photos?: number;
}

export interface ModelSourceImage {
  fileName: string;
  image: string;
}
export interface CreditsInfo {
  maxCredits: number;
  usedCredits: number;
}

export interface ViewsInfo {
  viewersStats: number;
  arStats: number;
}

export interface ModelsQueryFilters {
  category?: string;
  status?: string;
  pub_model_id?: string;
  rate?: number;
  is_shared?: string;
  email?: string;
  org_id?: string;
  from_date?: string;
  to_date?: string;
}

export interface ModelsApiResponse {
  models: Model[]; // models for current page
  count: number; // all models count
}

export type ModelQueryResponse = Response<Array<Model>>;

export enum ModelStatusesEnum {
  FAILED = -1,
  PENDING = 0,
  PROCESSING = 1,
  COMPLETED = 2,
}
export enum ModelStatusesNamesEnum {
  FAILED = 'error',
  PENDING = 'pending',
  PROCESSING = 'processing',
  COMPLETED = 'done',
  APPROVED = 'approved',
}

export enum ModelCategoryEnum {
  Eyewear = 1,
  Footwear = 2,
  Watches = 3,
}

export const modelStatuses = [
  {id: -1, label: 'Error'},
  {id: 0, label: 'Pending'},
  {id: 1, label: 'Processing'},
  {id: 2, label: 'done'},
];

export const categories = [
  {id: 1, name: 'Eyewear'},
  {id: 2, name: 'Footwear'},
  {id: 3, name: 'Watches'},
];

export const modelsSortingOptionsForReseller = [
  {fieldName: 'model_name', label: 'Model name'},
  {fieldName: 'created_date', label: 'Created date'},
  {fieldName: 'status_name', label: 'Status'},
];

export const modelsSortingOptions = [
  {fieldName: 'model_name', label: 'Model name'},
  {fieldName: 'created_date', label: 'Created date'},
  {fieldName: 'status_name', label: 'Status'},
];

export const modelRatingOptions = [
  {id: '-1', label: 'Not Reviewed'},
  // {id: '0', label: 'Rejected'},
  {id: '5', label: 'Approved'},
];

export enum UserRolesEnum {
  ADMIN = 1,
  USER = 2,
  RESELLER_ADMIN = 3,
  RESELLER_CUSTOMER = 4,
}
export type ModelSharingType = '3d' | 'ar';

export type ArAppModelType = 'vree' | 'fbx';
export interface ARModelPageParameters {
  id?: string | null;
  is_external?: boolean;
  is_sample?: boolean;
  location?: string | null;
  name?: string | null;
  query?: string | null;
}
