import {useEffect, useState} from 'react';
import ReactApexChart from 'react-apexcharts';
import {ApexOptions} from 'apexcharts';
import {ErrorPlaceholder} from '../../../../../../shared/components/ErrorPlaceholder';
import {parseError} from '../../../../../../shared/utils';
import {getServersRunTimeSummary} from '../../../api/users-api';
import {ServersTimeTracker} from '../../../../models-management/types';
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers';
import clsx from 'clsx';

const ServersRunTimeSummaryChart = () => {
  const [error, setError] = useState('');
  const [serversTime, setServersTime] = useState<ServersTimeTracker | null>(null);
  const [loading, setLoading] = useState(false);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(12);
  const [totalHours, setTotalHours] = useState(12);

  useEffect(() => {
    fetchData(totalHours);
  }, []);

  const fetchData = async (hours: number) => {
    setLoading(true);
    setServersTime(null);
    try {
      const data = await getServersRunTimeSummary(hours);
      validateAndSetData(data);
    } catch (err) {
      setError(parseError(err));
    } finally {
      setLoading(false);
    }
  };

  const validateAndSetData = (data: ServersTimeTracker) => {
    if (data.models) {
      const running = parseTimeToMinutes(data.models.totalRunningTime || '0h 0m 0s');
      const stopped = parseTimeToMinutes(data.models.totalStoppedTime || '0h 0m 0s');
      if (running === 0 && stopped === 0) {
        data.models.error = 'The instance was in stopped mode for the selected period';
      }
    }
    if (data.videos) {
      const running = parseTimeToMinutes(data.videos.totalRunningTime || '0h 0m 0s');
      const stopped = parseTimeToMinutes(data.videos.totalStoppedTime || '0h 0m 0s');
      if (running === 0 && stopped === 0) {
        data.videos.error = 'The instance was in stopped mode for the selected period';
      }
    }
    setServersTime(data);
  };

  const handleShowHours = () => {
    const calculatedHours = days * 24 + hours;
    setTotalHours(calculatedHours);
    fetchData(calculatedHours);
  };

  const createChartSeries = (running: string, stopped: string) => {
    const runningMinutes = parseTimeToMinutes(running);
    const stoppedMinutes = parseTimeToMinutes(stopped);
    const total = runningMinutes + stoppedMinutes;

    if (total === 0) return [];
    return [(runningMinutes / total) * 100, (stoppedMinutes / total) * 100];
  };

  const createTooltipFormatter = (running: string, stopped: string) => {
    return (val: number, {seriesIndex}: {seriesIndex: number}) => {
      const time = seriesIndex === 0 ? running : stopped;
      return `${val.toFixed(1)}% (${time})`;
    };
  };

  const modelsSeries =
    serversTime?.models && !serversTime.models.error
      ? createChartSeries(serversTime.models.totalRunningTime, serversTime.models.totalStoppedTime)
      : [];

  const videosSeries =
    serversTime?.videos && !serversTime.videos.error
      ? createChartSeries(serversTime.videos.totalRunningTime, serversTime.videos.totalStoppedTime)
      : [];

  const commonOptions = (running: string, stopped: string): ApexOptions => ({
    chart: {type: 'pie'},
    labels: ['Running Time', 'Stopped Time'],
    colors: ['#007BFF', '#66B2FF'],
    legend: {position: 'bottom'},
    tooltip: {
      y: {
        formatter: createTooltipFormatter(running, stopped),
      },
    },
  });

  return (
    <div className='mt-5'>
      <div className='card mb-4 shadow-sm'>
        <div
          className='card-header text-white text-center p-4'
          style={{backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/top-green.jpg')})`}}
        >
          <h3
            className='fw-bold fs-2x align-items-sm-center justify-content-center'
            style={{color: '#9b6ff7'}}
          >
            Server Runtime Summary
          </h3>
        </div>

        <div className='card-body'>
          <div className='row mb-4 align-items-center'>
            <div className='col-md-5 d-flex align-items-center'>
              <input
                type='number'
                className={clsx('form-control form-control-lg form-control-solid')}
                value={days}
                onChange={(e) => setDays(parseInt(e.target.value) || 0)}
              />
              <label className='ml-2 col-lg-3  fw-bold fs-6'>Days</label>
            </div>
            <div className='col-md-5 d-flex align-items-center'>
              <input
                type='number'
                className={clsx('form-control form-control-lg form-control-solid')}
                value={hours}
                onChange={(e) => setHours(parseInt(e.target.value) || 0)}
              />
              <label className='ml-2 col-lg-3  fw-bold fs-6'>Hours</label>
            </div>
            <div className='col-md-2'>
              <button className='btn btn-primary' onClick={handleShowHours}>
                Show
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='row mt-4'>
        {(['models', 'videos'] as Array<keyof ServersTimeTracker>).map((type) => {
          const tracker = serversTime?.[type]; // Ensure tracker is not undefined

          return (
            <div className='col-lg-6' key={type}>
              <div
                className='card'
                style={{
                  minHeight: '400px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span className='fw-semibold text-gray-500 fs-3 capitalized card-header align-items-sm-center justify-content-center'>
                  {type.charAt(0).toUpperCase() + type.slice(1)} Runtime
                </span>
                <div
                  className='card-body'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '300px',
                  }}
                >
                  {loading ? (
                    <div className='text-center fw-bold'>Loading...</div>
                  ) : tracker?.error ? (
                    <div className='text-center text-danger fw-bold'>{tracker.error}</div>
                  ) : tracker ? (
                    <ReactApexChart
                      options={commonOptions(
                        tracker?.totalRunningTime ?? '0h 0m 0s',
                        tracker?.totalStoppedTime ?? '0h 0m 0s'
                      )}
                      series={type === 'models' ? modelsSeries : videosSeries}
                      type='pie'
                      height={300}
                    />
                  ) : (
                    <div className='text-center text-danger fw-bold'>Data unavailable</div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const parseTimeToMinutes = (time: string) => {
  const match = time.match(/(\d+)h\s*(\d+)m\s*(\d+)s/);
  if (!match) return 0;
  const [, hours, minutes, seconds] = match.map(Number);
  return hours * 60 + minutes + Math.round(seconds / 60);
};

export default ServersRunTimeSummaryChart;
